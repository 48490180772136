//
// Utility classes
// --------------------------------------------------


// Floats
// -------------------------

.clearfix {
  @include clearfix;
}
.center-block {
  @include center-block;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}


// Toggling content
// -------------------------

// Note: Deprecated .hide in favor of .hidden or .sr-only (as appropriate) in v3.0.1
.hide {
  display: none !important;
}
.show {
  display: block !important;
}
.invisible {
  visibility: hidden;
}
.text-hide {
  @include text-hide;
}


// Hide from screenreaders and browsers
//
// Credit: HTML5 Boilerplate

.hidden {
  display: none !important;
}

.top-sm { margin-top: 10px; }
.top-md { margin-top: 20px; }
.top-lg { margin-top: 40px; }

.right-sm { margin-right: 10px; }
.right-md { margin-right: 20px; }
.right-lg { margin-right: 40px; }

.bottom-sm { margin-bottom: 10px; }
.bottom-md { margin-bottom: 20px; }
.bottom-lg { margin-bottom: 40px; }

.left-sm { margin-left: 10px; }
.left-md { margin-left: 20px; }
.left-lg { margin-left: 40px; }

// For Affix plugin
// -------------------------

.affix {
  position: fixed;
}
