// Tabs height
$scrtabs-tabs-height: 69px !default;

// Border color (bootstrap lt gray)
$scrtabs-border-color: 1px solid rgb(221, 221, 221) !default;

// Foreground color (bootstrap blue)
$scrtabs-foreground-color: #222;

// Background color on hover (bootstrap gray)
$scrtabs-background-color-hover: rgb(238, 238, 238) !default;

.scrtabs-tab-container * {
  box-sizing: border-box;
}

.scrtabs-tab-container {
  height: $scrtabs-tabs-height;
  border-bottom: 1px solid #DDD;
  .tab-content {
    clear: left;
  }
}

.scrtabs-tabs-fixed-container {
  float: left;
  height: $scrtabs-tabs-height;
  overflow: hidden;
  width: 100% !important;
}

.scrtabs-allow-scrollbar .scrtabs-tabs-fixed-container {
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.scrtabs-tabs-movable-container {
  position: relative;
  .tab-content {
    display: none;
  }
}

.scrtabs-tab-scroll-arrow {
  border-top: none;
  color: #222;
  cursor: default;
  display: none;
  font-size: 22px;
  height: $scrtabs-tabs-height - 1;
  margin-bottom: -1px;
  padding-top: 19px;
  width: 75px;
  text-align: center;
  position: absolute;
  z-index: 2;
  &:hover {
    background-color: $scrtabs-background-color-hover;
  }
}

.scrtabs-tab-scroll-arrow:first-child {
  left: 0;
  background: -moz-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 20%, rgba(255,255,255,0) 100%);
  background: -webkit-linear-gradient(left, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 20%,rgba(255,255,255,0) 100%);
  background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 20%,rgba(255,255,255,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1);
}

.scrtabs-tab-scroll-arrow:first-child:hover {
  background: -moz-linear-gradient(left, rgba(221,221,221,1) 0%, rgba(228,228,228,1) 20%, rgba(255,255,255,0) 100%);
  background: -webkit-linear-gradient(left, rgba(221,221,221,1) 0%,rgba(228,228,228,1) 20%,rgba(255,255,255,0) 100%);
  background: linear-gradient(to right, rgba(221,221,221,1) 0%,rgba(228,228,228,1) 20%,rgba(255,255,255,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dddddd', endColorstr='#00ffffff',GradientType=1);
}

.scrtabs-tab-scroll-arrow:last-child {
  right: 0;
  background: -moz-linear-gradient(right, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 20%, rgba(255,255,255,0) 100%);
  background: -webkit-linear-gradient(right, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 20%,rgba(255,255,255,0) 100%);
  background: linear-gradient(to left, rgba(255,255,255,1) 0%,rgba(255,255,255,1) 20%,rgba(255,255,255,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1);
}

.scrtabs-tab-scroll-arrow:last-child:hover {
  background: -moz-linear-gradient(right, rgba(221,221,221,1) 0%, rgba(228,228,228,1) 20%, rgba(255,255,255,0) 100%);
  background: -webkit-linear-gradient(right, rgba(221,221,221,1) 0%,rgba(228,228,228,1) 20%,rgba(255,255,255,0) 100%);
  background: linear-gradient(to left, rgba(221,221,221,1) 0%,rgba(228,228,228,1) 20%,rgba(255,255,255,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dddddd', endColorstr='#00ffffff',GradientType=1);
}

.scrtabs-tab-scroll-arrow.scrtabs-disable {
  display: none !important;
}

.scrtabs-tabs-fixed-container ul.nav-tabs > li {
  white-space: nowrap;
}