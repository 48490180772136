// Sidebar

.sidebar {
	width: $sidebar-width;
	background: $sidebar-background;
	left: 0;
	top: 0;
	position: fixed;
	height: 100%;
	padding: 70px 0 0 0;
	color: #FFF;
}

.sidebar ul {
	padding-left: 0;
	list-style: none;
}

.sidebar ul li {

}

.sidebar ul li a {
	display: block;
	text-align: center;
	padding: 15px 0;
	transition: background ease-in-out 0.15s;
}

.sidebar ul li a h6 {
	color: #FFF;
	text-transform: uppercase;
	font-weight: 700;
}

.sidebar ul li a i {
	font-size: 36px;
}

.sidebar ul li a:hover {
	background: #028ed4;
	text-decoration: none;
}

.sidebar ul li a:hover i {
	color: #FFF;
}