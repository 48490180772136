// Panels

@mixin panel-variant($border, $heading-text-color, $heading-bg-color, $heading-border) {
  border-color: $border;
  background-color: #fff;

  & > .panel-heading {
    color: $heading-text-color;
    background-color: $heading-bg-color;
    border-color: $heading-border;
    padding: 0px 30px;

    + .panel-collapse > .panel-body {
      border-top-color: $border;
    }
    .badge {
      color: $heading-bg-color;
      background-color: $heading-text-color;
    }
    .btn {
      margin-top: 8px;
    }
    a:hover {
      color: #FFF;
    }
    a.btn.btn-inverse:hover {
      color: #00529e;
    }
  }
  & > .panel-footer {
    + .panel-collapse > .panel-body {
      border-bottom-color: $border;
    }
  }
}